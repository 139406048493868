import { useState, useEffect, useRef } from "react";
import Portal from "../common/Portal";
import { ReactComponent as IconX } from "../../assets/svg/x.svg";
import classnames from "classnames";

interface RadionButtonProps<T extends { value: string; label: string }> {
  group?: string;
  option: T;
  isChecked: boolean;
  onChange: React.Dispatch<React.SetStateAction<T | undefined>>;
}
const RadioButton = <T extends { value: string; label: string }>(props: RadionButtonProps<T>) => {
  const { group, option, isChecked, onChange } = props;

  return (
    <div className="filter-modal_radio-buttons">
      <label>
        {option.label}
        <input type="radio" value={option.value} checked={isChecked} name={group} onChange={() => onChange(option)} />
        <span className="radio-button"></span>
      </label>
    </div>
  );
};

interface FilterModalProps<T extends { value: any; label: string }> {
  children?: React.ReactNode;
  button?: React.ReactNode;
  title?: string;
  options?: Array<T>;
  group?: string;
  curValue?: T;
  contentClassNames?: string;
  loading?: boolean;
  onApply: (option: T) => void;
  onCancel?: () => void;
  onToggleModal?: () => void;
}

const FilterModal = <T extends { value: any; label: string }>(props: FilterModalProps<T>) => {
  const {
    children = null,
    button,
    title,
    group,
    contentClassNames,
    curValue,
    options,
    loading,
    onApply,
    onCancel,
    onToggleModal,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [scrollableBelow, setScrollableBelow] = useState(false);
  const [optionToApply, setOptionToApply] = useState(curValue);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!optionToApply) {
      setOptionToApply(curValue);
    }
  }, [curValue]);

  const toggleModal = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowModal(!showModal);
    onToggleModal && onToggleModal();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onCancelHook = () => {
    onCancel && onCancel();
    closeModal();
  };

  const onApplyHook = () => {
    if (onApply) {
      options && optionToApply && onApply(optionToApply);
    }
    closeModal();
  };

  const listOptions = () => {
    return (
      <div className="filter-modal_options">
        {options?.map((option) => {
          const isChecked = curValue && option.value === optionToApply?.value;

          return (
            <RadioButton key={option.value} group={group} option={option} isChecked={!!isChecked} onChange={setOptionToApply} />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {button && (
        <div className="select_modal">
          <div className={`select_modal__toggle`} onClick={toggleModal}>
            {button}
          </div>
        </div>
      )}
      {showModal && (
        <Portal>
          <div className="filter-modal">
            <div className="filter-modal_header">
              <div>{title}</div>
              <div className="filter-modal_close" onClick={onCancelHook}>
                <IconX />
              </div>
            </div>
            <div ref={content} className={classnames("filter-modal_content", contentClassNames)}>
              {loading ? (
                <div style={{ padding: "20px" }}>
                  <div className="spinthatshit-loader">
                    <div className="spinner"></div>
                  </div>
                </div>
              ) : options ? (
                listOptions()
              ) : (
                children
              )}
            </div>
            <div className={`filter-modal_buttons ${scrollableBelow ? "fade-content" : ""}`}>
              <div onClick={onCancelHook} className="btn-transparent filter-modal_cancel">
                Cancel
              </div>
              <div onClick={onApplyHook} className="btn-blue filter-modal_apply">
                Apply
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default FilterModal;
